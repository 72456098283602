body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1 {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 53px;
  line-height: 86.3%;
  margin: 0;
  padding-top: 30vh;
  padding-left: 20px;
}
@media only screen and (min-width: 600px) {
  h1 {
    font-size: 100px;;
  }
}
h2 {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 38px;
  color: black;
}
h3 {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 38px;
  padding-left: 20px;
}
p {
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 16px;
}
.highlights {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #8E8E93;
  white-space: pre-line;
  padding-top: 50px;
}
#header {
  margin: 0;
  background-color: #fff;
  min-width: 100vw;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
@media only screen and (max-width: 600px) {
  #header {
    background-position: center right;
  }
}
.contacts {
  padding: 20px;
  position: absolute;
  bottom: 30px;
  margin-top: 0px;
  font-size: 20px;
  font-weight: 300;
}
.contacts a {
  color: #8E8E93;
  font-weight: 200;
  text-decoration-thickness: 1px;
  position: absolute;
  bottom: 150px;
}
.projects {
  min-height: 100vh;
}
.projects:last-of-type .right {
  margin-bottom: 0px;
}
.projects h2 , .projects p  {
  padding-left: 20px;
  padding-right: 20px;
}
@media only screen and (min-width: 600px) {
  .projects {
    min-height: auto;
  }
  .projects h2 {
    margin-top: 0px;
  }
  .projects p:nth-child(-n+1) {
    padding-top: 20px;
  }
  .highlights {
    padding-top: 20%;
  }
}
img {
  width: 100%;
}
video {
  width: 100%;
}
.container {
  position: relative;
  height: 100%;
}
.more {
  font-family: Helvetica Neue;
  font-weight: bold;
  font-size: 24px;
  color: black;
  position: absolute;
  /* padding: 10px; */
  bottom: -10px;
  right: 0px;
}
iframe {
  width: 90%;
  height: auto;
  display: block;
  margin: auto;
  height: 23vh;
}
@media only screen and (min-width: 600px) {
  iframe {
    /* padding: 40px; */
    width: 96%;
    height: 100vh;
  }
  .App {
    width:80vw;
  }
}
#back {
  position: fixed;
  text-decoration: none;
  color: black;
  margin-left: 20px;
  font-family: Helvetica Neue;
  font-weight: bold;
  font-size: 24px;
  top: 0;
  z-index: 10;
}
@media only screen and (min-width: 600px) {
  .left {
    float: left;
    width: 30%;
    margin-bottom: 80px;
  }
  .right {
    float: left;
    width: 70%;
    margin-bottom: 80px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 25px;
  }
  .third {
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .third img, .third video {
    width: 33%; 
    height: 100vh;
  }
  .third img:nth-child(-n+2), .third video {
    padding-right: 20px;
  }
  .twothree {
    width: 67%;
  }
  .threethree {
    width: 32%;
  }
}
@media only screen and (max-width: 600px) {
  .App div:nth-of-type(2) {
    margin-bottom: 50px; 
  };
}
.underline {
  text-decoration-color: black;
  text-decoration-thickness: 5px;
}
.paddingTop {
  padding-top: 35px;
}